import React, { Component } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import styles from './button.module.styl';

const cx = classnames.bind(styles);

const buttonVariants = [
  'primary',
  'secondary',
  'text',
  'alert',
];

class Button extends Component {
  render() {
    const {
      active,
      className,
      children,
      disabled,
      href,
      onClick,
      tag = 'button',
      type = 'button',
      variant = 'primary',
      ...attributes
    } = this.props;
    const dataMrAss = this.props['data-mr-ass'];
    const classes = cx(
      className,
      'button',
      variant,
      { active, disabled },
    );
    let Component = 'button';
    if (href) {
      Component = 'a';
    } else if (tag) {
      Component = tag;
    }
    return (
      <Component {...attributes} onClick={onClick} disabled={disabled} type={type} className={classes} href={href} data-mr-ass={dataMrAss}>
        {children}
      </Component>
    );
  }
}

Button.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  type: PropTypes.oneOf(['button', 'reset', 'submit', null]),
  variant: PropTypes.oneOf([...buttonVariants, null]),
  ['data-mr-ass'] : PropTypes.string,
  children : PropTypes.node,
};

Button.variants = buttonVariants;

export default Button;
