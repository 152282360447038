import _ from 'lodash/fp';
import axios from 'axios';
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import styles from './oauth.module.styl';

import Button from '../../UI/Button';
import Icon from '../../UI/Icon';
import Input from '../../UI/Input';

const cx = classnames.bind(styles);
const propTypes = {
  onError: PropTypes.func,
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
};
// Google's OAuth 2.0 endpoint for requesting an access token
const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';
// remove hash
const clearHash = () => {
  window.history.pushState("", document.title, window.location.pathname + window.location.search);
};
const login = (payload) => axios.post('/api/agent/login', payload)
  .then(_.get('data'))
  .then(() => {
    setTimeout(() => {

      clearHash();
      window.location.reload(true);
    }, 1000);
  });

/** @component */
export default function OAuth({
  onError,
  showLoading,
  hideLoading,
}) {
  const [showAltLogin, setShowAlt] = useState(false);

  const handleAltClick = useCallback((e) => {
    e.preventDefault();
    setShowAlt(true);
  });

  const handleGoToGoogClick = useCallback((e) => {
    e.preventDefault();
    setShowAlt(false);
  });

  const handleGoogleClick = useCallback(() => {

    showLoading();
    axios.get('/api/agent/getGoogleLoginParams')
      .then(_.get('data'))
      .then((attrs) => {

        // Create <form> element to submit parameters to OAuth 2.0 endpoint.
        const form = document.createElement('form');
        form.setAttribute('method', 'GET'); // Send as a GET request.
        form.setAttribute('action', oauth2Endpoint);

        // Add form parameters as hidden input values.
        _.flow(
          _.toPairs,
          _.forEach(([name, attr]) => {
            const input = document.createElement('input');
            input.setAttribute('type', 'hidden');
            input.setAttribute('name', name);
            input.setAttribute('value', attr);
            form.appendChild(input);
          }),
        )(attrs);

        // Add form to page and submit it to open the OAuth 2.0 endpoint.
        document.body.appendChild(form);
        form.submit();
      })
      .catch((err) => {
        clearHash();
        console.error(err); // eslint-disable-line no-console
        onError(err.message || 'Get Params Error');
        hideLoading();
      });

  }, []);

  useEffect(() => {

    const url = window.location.href;
    let accessToken;

    if (url.match(/access_token=([^&]*)/) && url.match(/access_token=([^&]*)/)[1]) {
      accessToken = url.match(/access_token=([^&]*)/)[1];
    }

    const cookie = document.cookie.trim().split(';');
    const cleanCookie = cookie.map(function(element) {
      return element.trim();
    });

    // If agent was logged out from Color Bar we also log them out from Google App.
    if (~cleanCookie.indexOf('logOutEvent=1')) {
      document.cookie = "logOutEvent=0; expires=Thu, 01 Jan 1970 00:00:00 UTC";
    } else if (accessToken) {
      const payload = {
        type: 'google',
        accessToken,
      };

      showLoading();
      login(payload).catch((err) => {
        onError(_.get('response.data.message', err) || 'Google OAuth Login Error');
        hideLoading();
      });
    }
  }, []);

  const [name, setName] = useState('');
  const [pw, setPW] = useState('');
  const handleNameChange = useCallback(_.flow(_.get('target.value'), setName), [setName]);
  const handlePWChange = useCallback(_.flow(_.get('target.value'), setPW), [setName]);

  const handleLoginSubmit = useCallback((e) => {
    if (e) {
      e.preventDefault();
    }

    if (pw.length <= 3 || pw.length >= 255 || name.length <= 1 || name.length >=255) {
      return;
    }
    showLoading();
    login({
      type: 'normal',
      username: name,
      password :pw,
    }).catch((err) => {
      hideLoading();
      onError(_.get('response.data.message', err));
    });
  }, [pw, name]);

  return (
    <div className={ cx('o-auth') }>

      { showAltLogin ? (
        <>
          <form className={ cx('form') } action='none' onSubmit={ handleLoginSubmit } disabled={  pw.length <= 3 || pw.length >= 255 || name.length <= 1 || name.length >=255  }>
            <Input.Box
              groupClassName={ cx('input') }
              model='username'
              label='username'
              value={ name }
              invalid={ name.length >= 1 && name.length >= 15 }
              onChange={ handleNameChange }
            />

            <Input.Box
              groupClassName={ cx('input') }
              type='password'
              model='password'
              label='password'
              value={ pw }
              invalid={ pw.length >=255 }
              onChange={ handlePWChange }
            />

            <Button type='submit' className={ cx('button') } variant='secondary' onClick={ handleLoginSubmit } disabled={ pw.length <= 3 || pw.length >= 255 || name.length <= 1 || name.length >=255 }>
              Login
            </Button>
          </form>

          <a className={ cx('link') } href='#/show-alt-login' onClick={handleGoToGoogClick}>
            Login with your google email
          </a>
        </>
      ) : (
        <>
          <Button className={ cx('button') } variant='secondary' onClick={ handleGoogleClick }>
            <Icon className={ cx('icon') } iconName={ Icon.iconNames.google } preserveSvgColor={ true } />
            Sign in with Google
          </Button>
          <a className={ cx('link') } href='#/show-alt-login' onClick={handleAltClick}>
            Alternate Login (email/password)
          </a>
        </>
      ) }

    </div>
  );
}

OAuth.displayName = 'OAuth';
OAuth.propTypes = propTypes;
