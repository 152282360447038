import _ from 'lodash/fp';
import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import styles from './money.module.styl';

const cx = classnames.bind(styles);

const propTypes = {
  className: PropTypes.string,
  groupClassName: PropTypes.string,
  model: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onInput: PropTypes.func,
  value: PropTypes.any,
  type: PropTypes.string,
  invalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disableSpinBox: PropTypes.bool,
  'data-mr-ass': PropTypes.string,
};

/** @component */
export default function Money({
  className,
  groupClassName: _groupClassName,
  model,
  onChange,
  onBlur,
  invalid,
  disabled,
  value,
  type,
  disableSpinBox, // disable the spinner buttons
  ...rest
}) {
  const { current: isControlled } = useRef(value != null);
  const [iv, ivOnChange] = useState(value);

  const handleChange = useCallback(
    (e) => {
      //This isnt a good way to do it but we need a fix asap
      if (e.target.value && e.target.value.length > 1) {
        if (e.target.value[0] === "0") {
          e.target.value = e.target.value.slice(1);
        }
      }

      if (_.isFunction(onChange)) {
        onChange(e);
      }

      ivOnChange(e.target.value);
    },
    [onChange, ivOnChange],
  );

  const handleOnBlur = useCallback(e => {
    if (value) {
      const valueSplit = String(value).split('.');
      const decimals = valueSplit && valueSplit.length > 1 ? valueSplit[1] : null;
      if (decimals) {
        if (decimals.length == 1) {
          const newValue = Number(`${String(value)}0`).toFixed(2);
          e.target.value = newValue;
          if (_.isFunction(onBlur)) {
            onBlur(e);
          }
          ivOnChange(newValue);
        }
      }
    }
  }, [value, onChange, ivOnChange]);

  const inputClassName = cx(className, {
    'has-value': isControlled ? value : iv,
    input: true,
    invalid,
    disabled,
  });

  const groupClassName = cx('group', _groupClassName);

  const restProps = _.omit(['className'], rest);

  type = type || "number";

  return (
    <div className={groupClassName}>
      <span className={cx('label')}>$</span>
      <input
        id={model}
        className={cx(inputClassName, {'disable-spinbox': disableSpinBox})}
        value={value}
        onChange={handleChange}
        type={type}
        min="0"
        step="0.01"
        disabled={disabled}
        onBlur={handleOnBlur}
        {...restProps}
      />
    </div>
  );
}

Money.displayName = 'Input(Money)';
Money.propTypes = propTypes;