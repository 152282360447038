import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import styles from './spinner.module.styl';

const cx = classnames.bind(styles);
const propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  tagName: PropTypes.string,
};

/** @component */
export default function Spinner({
  className,
  onClick,
  tagName: Tag = 'div',
}) {

  if (typeof onClick === 'function') {
    Tag = 'button';
  }

  return (
    <Tag className={ cx('spinner-wrap', className) } onClick={onClick}>
      <div className={ cx('spinner') }>
        Loading...
      </div>
    </Tag>
  );
}

Spinner.displayName = 'Spinner';
Spinner.propTypes = propTypes;

/** @component */
export function Loading({
  className,
  onClick,
  tagName: Tag = 'div',
}) {

  if (typeof onClick === 'function') {
    Tag = 'button';
  }

  return (
    <Tag className={ cx('loading', className) } onClick={onClick}>
      <div className={ cx('bounce1') } />
      <div className={ cx('bounce2') } />
      <div className={ cx('bounce3') } />
    </Tag>
  );
}

Loading.propTypes = propTypes;
Loading.displayName = 'Loading';
