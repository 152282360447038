import ReactDOM from 'react-dom';

import './index.styl';
import { createApp } from './Login';
import * as serviceWorker from './serviceWorker';
import { getReduxNativeBridgeWrapper } from './Native-Bridge';

const {
  document,
  __data__: {
    isGoogleLogin = false,
  } = {},
} = window;
const DOMContainer = document.getElementById('app');

if (!DOMContainer) {
  throw new TypeError('Dom Container could not be found');
}

const nativeBridge = getReduxNativeBridgeWrapper(window);

const { appElement } = createApp({ isGoogleLogin, nativeBridge });

ReactDOM.render(appElement, DOMContainer);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// hot module reloading
if (module.hot) {
  module.hot.accept('./Login', () => {
    /* eslint-disable-next-line no-console */
    console.log('reloading app');
    const { createApp } = require('./Login');
    const { appElement } = createApp({ isGoogleLogin, nativeBridge });
    ReactDOM.render(appElement, DOMContainer);
  });
}
