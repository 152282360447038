import React from 'react';
import PropTypes from 'prop-types';

import cbLogo from './color_bar_logo.svg';

const propTypes = {
  className: PropTypes.string,
};

/** @component */
export default function ColorBarLogo({ className }) {
  return (
    <div className={ className }
      dangerouslySetInnerHTML={{ __html: cbLogo }}
    />
  );
}

ColorBarLogo.displayName = 'ColorBarLogo';
ColorBarLogo.propTypes = propTypes;
