import _ from 'lodash/fp';
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from './Button';

const propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
};

/**
 * Use this button when you need to open a url from within the wrapper
 *
 * @component
 */
export default function NativeButton({ href, onClick, ...rest }) {
  const [hasOpenNativeUrl] = useState(
    () => _.isFunction(_.get('nativeBridge.coreUI.openNativeURL', window)),
    [],
  );

  const handleClick = useCallback(() => {
    if (hasOpenNativeUrl && href) {
      return window.nativeBridge.coreUI.openNativeURL(href).catch((err) =>
        console.error(err), // eslint-disable-line no-console
      );
    }

    if (href) {
      return window.open(href, '_blank');
    }

    if (_.isFunction(onClick)) {
      return onClick();
    }
  }, [href, onClick, hasOpenNativeUrl]);

  return <Button onClick={handleClick} {...rest} />;
}

NativeButton.displayName = 'NativeButton';
NativeButton.propTypes = propTypes;