import _ from 'lodash/fp';
import { useState, useMemo, useEffect, useRef } from 'react';
import Mousetrap from 'mousetrap';

/**
 * @typedef {object|Function} ReactRef object
 * @property {any} current the value to track between renders
 */

/**
 * Set ref
 * @param {ReactRef} ref a react ref to set a value on
 * @param {any} value any value to be set on the ref
 */
export function setRef(ref, value) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
}

/**
 * This will create a new function if the ref props change and are defined.
 * This means react will call the old forkRef with `null` and the new forkRef
 * with the ref. Cleanup naturally emerges from this behavior
 *
 * @param {ReactRef} refA a react reference
 * @param {ReactRef} refB a react reference
 * @returns {ReactRef} A new react reference that will update the combined references
 */
export const useForkRef = (refA, refB) => useMemo(() => {
  if (refA == null && refB == null) {
    return null;
  }
  return refValue => {
    setRef(refA, refValue);
    setRef(refB, refValue);
  };
}, [refA, refB]);

/**
 * @callback MoustrapCallback
 * @param {KeyboardEvent} e - HTML Keyboard Event
 * @param {string} combo - The key string combo that triggered the event
 */
/**
 * Use mousetrap hook
 *
 * @param {object} vals
 * @param {string|string[]} vals.key - A key, key combo, or array of combos according to Mousetrap documentation.
 * @param {MoustrapCallback} vals.handlePress - A function that is triggered on key combo catch.
 * @param {HTMLElement} vals.element - An HTML element to bind the events to. Defaults to window.document
 */
export const useMouseTrapEffect = ({
  key,
  element = window.document,
  handlePress = _.noop,
}) => {
  const keyRef = Array.isArray(key) ? key.join('') : key;

  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = handlePress;
  }, [handlePress]);

  useEffect(() => {
    const mousetrap = Mousetrap(element);

    mousetrap.bind(key, (evt, combo) => {
      savedCallback.current(evt, combo);
    });

    return () => {
      mousetrap.unbind(key);
    };
  }, [keyRef, element, key]);
};

/**
 * use interval hook
 *
 * @param {Function} callback
 * @param {number|void} delay - set to null to remove timeout
 */
export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    if (delay !== null) {
      const id = window.setInterval(() => savedCallback.current(), delay);
      return () => window.clearInterval(id);
    }
  }, [delay]);
}

/**
 * use timeout hook
 *
 * @param {Function} callback
 * @param {number|void} delay - set to null to remove timeout
 */
export function useTimeout(callback, delay) {
  const savedCallback = useRef(callback);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the timeout.
  useEffect(() => {
    if (delay !== null) {
      const id = window.setTimeout(() => savedCallback.current(), delay);
      return () => window.clearTimeout(id);
    }
  }, [delay]);
}

/**
 * use viewport size to track viewport size
 * When using data within a component render method, use the return value.
 * When using the data to dispatch an action to update state, use the callback
 * method to avoid state updates during a react render cycle which can cause
 * subtle bugs.
 *
 * @param {Window} window
 * @param {number} debounceTimeout
 * @param {Function} callback
 * @returns {object} - { height, width }
 */
export function useViewPortSize(window = {}, debounceTimeout = 200, callback = _.noop) {
  const windowRef = useRef(window);
  const callbackRef = useRef();
  const isClient = !!window.document;
  const [ windowSize = {}, setWindowSize ] = useState({ height: window.innerHeight, width: window.innerWidth });

  useEffect(() => {
    windowRef.current = window;
  }, [window]);

  const getSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const innerCallback = () => {
    setWindowSize(getSize());
    callback(getSize());
  };

  if (!callbackRef.current) {
    if (debounceTimeout) {
      callbackRef.current = _.debounce(debounceTimeout, innerCallback);
    } else {
      callbackRef.current = innerCallback;
    }
  }

  useEffect(() => {
    if (!isClient) {
      return;
    }

    window.addEventListener('resize', callbackRef.current);

    return () => window.removeEventListener('resize', callbackRef.current);
  }, [isClient, window]);

  return windowSize;
}

/**
 * useScript - Add third party script from src url to the body
 *
 * @param {object} params
 * @param {string} params.src - Script src uri
 * @param {object?} params.document - optional DOM element
 *
 */
export function useScript({
  src,
  document = window.document,
}) {
  useEffect(() => {
    if (!src) {
      return;
    }

    let tag = document.createElement('script');
    tag.async = false;
    tag.src = src;
    const body = document.getElementsByTagName('body');
    body[0].appendChild(tag);

  }, [src, document]);
}


/**
 * useDebounce - Debounce a value
 * @param {any} value - value to debounce
 * @param {number} delay - delay in ms
 * @returns {any} - debounced value
 * @example
 * const debouncedValue = useDebounce(value, 500);
 */
export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}