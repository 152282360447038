import _ from 'lodash/fp';
import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import styles from './input.module.styl';
import Money from './Money';

const cx = classnames.bind(styles);

const variants = {
  flatbar: 'fatbar',
  box: 'box',
};

const propTypes = {
  className: PropTypes.string,
  groupClassName: PropTypes.string,
  label: PropTypes.string,
  model: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  invalid: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  disabled: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  variant: PropTypes.oneOf([
    ...Object.keys(variants),
    null,
  ]),

  'data-mr-ass': PropTypes.string,
};

/** @component */
export default function Input({
  className,
  groupClassName: _groupClassName,
  label,
  model,
  onChange,
  invalid,
  disabled,
  value,
  variant = variants.flatbar,
  ...rest
}) {
  const { current: isControlled } = useRef(value != null);
  const [ iv, ivOnChange ] = useState(value);

  const handleChange = useCallback((e) => {
    const v = e.target.value;

    if (_.isFunction(onChange)) {
      onChange(e);
    }

    ivOnChange(v);
  }, [onChange, ivOnChange]);

  const inputClassName = cx(className, {
    'has-value': isControlled ? value : iv,
    input: true,
    invalid,
    disabled
  });

  const groupClassName = cx('group', variant, _groupClassName);

  const restProps = _.omit(['className'], rest);

  return (
    <div className={ groupClassName }>
      <label htmlFor={ model }>
        <input id={ model } className={ inputClassName } value={ isControlled ? value: iv } onChange={ handleChange } { ...restProps } />
        <span className={ cx('label') }>{ label }</span>
        { variant === variants.flatbar && <span className={ cx('bar') } /> }
        <span className={ cx('highlight') } />
      </label>
    </div>
  );
}

Input.displayName = 'Input';
Input.propTypes = propTypes;

Input.Money = Money;

Input.Box = (props) => {
  return <Input variant={variants.box} {...props} />;
};

Input.Box.prototype = {
  ...Input.prototype,
  variant: null,
};
Input.Box.displayName = 'Input.Box';