import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import styles from './app.module.styl';
import NativeBridgeContext from '../NativeBridgeContext';

import NumPad from '../Num-Pad';
import OAuth from '../OAuthLogin';

import CBLogo from '../../UI/Color-Bar-Logo';
import { Loading } from '../../UI/Spinner';
import { useTimeout } from '../../utils/react';

const cx = classnames.bind(styles);

const propTypes = {
  isGoogleLogin: PropTypes.bool,
};

/** @component */
function Layout({
  isGoogleLogin,
}) {
  const [hasError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useTimeout(() => {
    setLoading(false);
  }, isLoading ? 5000 : null);

  const [showNumPad, setShowNumPad] = useState(false);
  useEffect(() => {
    if (!isGoogleLogin) {
      const path = window.location.pathname;
      const validNumPadApp = path === '/foss' || path === '/colorist';
      setShowNumPad(validNumPadApp);
      setError(validNumPadApp ? false : 'Opps, something went wrong.');
    }
  }, [isGoogleLogin]);

  const showLoading = useCallback(() => setLoading(true), [setLoading]);
  const hideLoading = useCallback(() => setLoading(false), [setLoading]);

  return (
    <div className={ cx('login') }>
      <div className={ cx('logo-wrap') }>
        <CBLogo className={ cx('logo') } />
      </div>

      <div className={ cx('error-msg') }>
        { hasError && (
          <div className={ cx('error') }>
            { typeof hasError === 'string' ?
                hasError :
                'Opps, something went wrong. Please try again.'
            }
          </div>
        )}
      </div>

      { isGoogleLogin ? (
        <OAuth
          onError={ setError }
          showLoading={ showLoading }
          hideLoading={ hideLoading }
        />
      ) : showNumPad ? (
        <NativeBridgeContext.Consumer>
          { (nativeBridge) => (
            <NumPad
              onError={ setError }
              showLoading={ showLoading }
              hideLoading={ hideLoading }
              securityService={ nativeBridge.getSecurityService() }
            />
          ) }
        </NativeBridgeContext.Consumer>
      ) : ''}
      { isLoading && (
        <div className={ cx('loading') }>
          <Loading className={ cx('spiner') } />
        </div>
      ) }
    </div>
  );
}

Layout.displayName = 'Layout(Login)';
Layout.propTypes = propTypes;

export default Layout;
