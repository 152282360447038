import React from 'react';

import App from './Layout';
import NativeBridgeContext from './NativeBridgeContext';

export const createApp = ({ isGoogleLogin, nativeBridge }) => {
  const appElement = (
    <NativeBridgeContext.Provider value={ nativeBridge }>
      <App
        isGoogleLogin={ isGoogleLogin }
        nativeBridge={ nativeBridge }
      />
    </NativeBridgeContext.Provider>
  );

  return { appElement };
};
