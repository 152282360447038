/* eslint-disable jsdoc/check-param-names */
import _ from 'lodash/fp';

const keyMirror = _.flow(
  _.map((key) => [key, key]),
  _.fromPairs,
);

const nativeEventType = keyMirror([
  'REMOTE_NOTIFICATION_SELECTED',
  'PUSH_TOKEN_UPDATED',
]);

const intents = keyMirror([
  /*  these may have been removed */
  'stopTimer',
  'addExtraMinutes',
  'stopTimerAndShow',
  /*  ^ these may have been removed */

  // capitalization intentional
  'View',

  'pushTokenUpdated',
  'viewBopisOrders',
  'viewGladlySMS',

  // mobile app intents
  'clientUpsell',
  'reservationPaid',

  // devtools
  'toggleColorCrewFlag',
  'toggleDevFlag',
]);

const coreUIEvents = keyMirror(['ENTER_FOREGROUND']);

export const nativeBridgeStub = {
  isStub: true,
  /**
   * Get the iOS App Version Number
   *
   * @returns {string} iOS App Bundle Version String
   */
  getAppVersion() {
    return 'browser';
  },

  /**
   * Get the iOS App Bundle Identifier
   *
   * @returns {string} iOS App Bundle Identifier String
   */
  getAppBundleIdentifier() {
    return `com.madison-reed.browser-${process.env.NODE_ENV}`;
  },

  /**
   * Check whether the given native context is available
   *
   * @param {string} name name of the context to check
   * @returns {boolean} if the specified context is available
   */
  isContextAvailable(/** contextName */) {
    return false;
  },

  notifications: {

    /**
     * Attach observer for event of given name.
     *
     * @param {string} name function unique name
     * @param {Function} listener function with signature function(e:Object))
     */
    addListener() {
      /* eslint-disable-next-line no-console */
      console.log('[NativeBridgeStub] addListener not available');
    },

    /**
     * Remove observer 'listener'.
     *
     * @param {string} name function unique name
     * @param {Function} listener   function to remove
     */
    removeListener() {
      /* eslint-disable-next-line no-console */
      console.log('[NativeBridgeStub] removeListener not available');
    },
  },
  coreUI: {
    /**
     * Attach observer for event of given name.
     *
     * @param {string} name function unique name
     * @param {Function} listener function with signature function(e:Object))
     */
    addListener() {},

    /**
     * Remove observer 'listener'.
     *
     * @param {string} name function unique name
     * @param {Function} listener   function to remove
     */
    removeListener() {},
  },
};

export const getReduxNativeBridgeWrapper = (window = {}) => {
  // convenience: allow the app to be tested on desktop browser outside the wrapper,
  // to providing no-op stub for the objects injected by the native app.
  const hasIOsBridge = !!window.nativeBridge;
  const nativeBridge = (window.nativeBridge =
    window.nativeBridge || nativeBridgeStub);

  /*
   * Dev Only Utils
   * No used when dev in wrapper
   *
   * Allows trigger notification intents through the console
   */
  if (!hasIOsBridge && process.env.NODE_ENV !== 'production') {
    const EventEmitter = require('events');
    const notifications = new EventEmitter();
    const coreUI = new EventEmitter();
    nativeBridge.notifications.addListener =
      notifications.addListener.bind(notifications);

    if (nativeBridge.coreUI) {
      nativeBridge.coreUI.addListener = coreUI.addListener.bind(coreUI);
    }

    window.__notification__ = {
      intents,
      eventCategories: nativeEventType,
      coreUIEvents,
      // for debug and dev purposes
      // these properties do not exists in production
      dispatch: (eventType, ...args) => notifications.emit(eventType, ...args),
      dispatchIntent(intent, args) {
        this.dispatch(nativeEventType.REMOTE_NOTIFICATION_SELECTED, {
          extraParams: _.set('intent', intent, args),
        });
      },
      dispatchPushToken(token) {
        this.dispatch(nativeEventType.PUSH_TOKEN_UPDATED, { token });
      },
      dispatchCoreUI: (eventType, ...args) => coreUI.emit(eventType, ...args),
      dispatchEnterForeground() {
        this.dispatchCoreUI(coreUIEvents.ENTER_FOREGROUND);
      },

      dispatchViewBopis(identifier) {
        this.dispatchIntent(intents.viewBopisOrder, { identifier });
      },

      dispatchClientUpsell(reservationId, productId, productName) {
        this.dispatchIntent(intents.clientUpsell, {
          identifier: reservationId,
          productId,
          productName,
        });
      },

      dispatchReservationPaid(reservationId) {
        this.dispatchIntent(intents.reservationPaid, {
          identifier: reservationId,
        });
      },

      dispatchToggleColorCrewFlag() {
        this.dispatchIntent(intents.toggleColorCrewFlag);
      },
      dispatchDevFlag() {
        this.dispatchIntent(intents.toggleDevFlag);
      },
      dispatchViewGladlySMS() {
        this.dispatchIntent(intents.viewGladlySMS);
      },
    };
  }
  /**
   * End dev utils
   */

  const api = {
    intents,
    coreUIEvents,

    // interface IntentToActions {
    //   [eventName: String]: ActionCreator
    // }
    //
    // init(intentToActionMap: IntentToActions, coreUIEventsToActions: IntentToActions) => void
    init(intentToActionMap, coreUIEventsToActions) {
      const getActionCreatorFromEvent = _.get(_, intentToActionMap);

      // (eventName: string) => ActionCreator|noop
      const getUiActionsFromEvent = _.flow(
        _.get(_, coreUIEventsToActions),
        _.defaultTo(_.noop),
      );

      // handle actions from notifications
      // i.e. when a user presses the notification in iOS
      nativeBridge.notifications.addListener(
        nativeEventType.REMOTE_NOTIFICATION_SELECTED,
        _.flow(_.get('extraParams'), ({ intent, ...rest }) =>
          _.flow(
            // gets a function that takes a native event type string and returns a redux action
            getActionCreatorFromEvent,
            _.defaultTo(() =>
              /* eslint-disable-next-line no-console */
              console.warn(`Couldn't find action for intent: ${intent}`),
            ),
            // call redux action
            (f) => f(rest),
          )(intent),
        ),
      );

      if (nativeBridge.coreUI) {
        nativeBridge.coreUI.addListener(
          coreUIEvents.ENTER_FOREGROUND,
          getUiActionsFromEvent(coreUIEvents.ENTER_FOREGROUND),
        );
      }
    },

    getSecurityService() {
      return _.isFunction(nativeBridge.getContext)
        ? nativeBridge.getContext('security')
        : nativeBridge.security;
    },

    getPrinterService() {
      let printer;
      try {
        printer = nativeBridge.getContext
          ? nativeBridge.getContext('printer')
          : // DEPRECATED method
            nativeBridge.printer;
      } catch (e) {
        console.error(e); /* eslint-disable-line no-console */
      }
      return printer;
    },

    getGladlyService() {
      let gladlyService;
      try {
        gladlyService = nativeBridge.getContext
          ? nativeBridge.getContext('gladly')
          : // DEPRECATED method
            nativeBridge.gladly;
      } catch (e) {
        console.error(e); /* eslint-disable-line no-console */
      }
      return gladlyService;
    },

    getAppVersion: () => nativeBridge.getAppVersion(),
    getAppBundleIdentifier: () => nativeBridge.getAppBundleIdentifier(),
  };

  return api;
};