import _ from 'lodash/fp';
import axios from 'axios';
import React, { useCallback, useState, useEffect, Fragment } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import styles from './num-pad.module.styl';
import { useMouseTrapEffect } from '../../utils/react';

const cx = classnames.bind(styles);

const propTypes = {
  onError: PropTypes.func,
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
  securityService: PropTypes.object,
  pinCode: PropTypes.array,
  pinCodeComplete: PropTypes.func,
};

/** @component */
function NumPad({
  onError,
  showLoading,
  hideLoading,
  securityService,
  pinCode,
  pinCodeComplete
}) {
  const [pc, setPin] = useState([]);

  const handleNumClick = useCallback((num) => {
    setPin(_.concat(_, num));
  }, [setPin]);

  const handleClearClick = useCallback(() => {
    setPin([]);
  }, [setPin]);

  const handleBackClick = useCallback(() => {
    setPin(_.dropRight(1));
  }, [setPin]);

  useMouseTrapEffect({
    key: _.flow(_.range(0), _.map(_.toString))(10),
    handlePress: useCallback(
      (e) => {
        e.preventDefault();
        _.flow(
          _.get('key'),
          _.toInteger,
          handleNumClick,
        )(e);
      },
      [handleNumClick]
    ),
  });

  useMouseTrapEffect({
    key: 'esc',
    handlePress: handleClearClick,
  });

  useMouseTrapEffect({
    key: 'backspace',
    handlePress: handleBackClick,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {

    if (!pc.length && pinCode && pinCode.length > 1) {
      return;
    }

    if (pinCode && pc.length != pinCode.length) {
      setPin(pinCode);
    }

    if (pc.length <= 5) {
      return;
    }

    if (pinCodeComplete) {
      return pinCodeComplete();
    }

    const headers = securityService ?
      securityService.getHeaders() :
      Promise.resolve({});

    showLoading();

    headers.then((headers) => axios.post(
      '/api/agent/ravenLogin',
      {
        code: pc.join('') ,
        appName: 'colorist',
      },
      { headers },
    ))
      .then(() => {
        //refresh page
        window.location.reload(true);
      })
      .catch(err => {
        const errorMessage = _.flow(
          _.get('response.data.error'),
          _.defaultTo(true)
        )(err);
        hideLoading();
        handleClearClick();
        onError(errorMessage);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinCode, pc]);

  return (
    <Fragment>

      <div className={ cx('enter-passcode') }>Enter Passcode</div>

      <div className={ cx('pip-wrap') }>
        {
          _.flow(
            _.range(0),
            _.map(_.add(1)),
            _.map((num) => (
              <div key={ num } className={ cx('pip', { lit: pc.length >= num }) } />
            )),
          )(6)
        }
      </div>

      <div className={ cx('keypad') }>
        {
          _.flow(
            _.range(1),
            _.map((num) => (
              <div key={ num } className={ cx('button-wrapper') }>
                <button onClick={ () => handleNumClick(num) } className={ cx('circle', 'login-num-button') } data-mr-ass='login-num-button'>
                  { num }
                </button>
              </div>
            ))
          )(10)
        }

        <div className={ cx('button-wrapper') } >
          <button onClick={ handleClearClick } className={ cx('circle', 'edit-button') } />
        </div>

        <div className={ cx('button-wrapper') }>
          <button onClick={ () => handleNumClick(0) } className={ cx('circle', 'login-num-button') } data-mr-ass='login-num-button'>
            0
          </button>
        </div>

        <div className={ cx('button-wrapper') }>
          <button onClick={ handleBackClick } className={ cx('circle', 'edit-button') } >
            back
          </button>
        </div>

      </div>
    </Fragment>
  );
}

NumPad.propTypes = propTypes;
NumPad.displayName = 'NumPad';

export default NumPad;
